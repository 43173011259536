import React, { useState } from 'react'
import { Grid, Fab, Typography } from '@material-ui/core'

import Layout from '../Layout'
import makeColors from '../lib/makeColors'

export default () => {
  const [colors, setColors] = useState(makeColors())

  return (
    <Layout colors={colors}>
      <Grid container>
        <Grid item>
          <Fab href="" size="large" variant="extended" color="secondary" disableRipple={true} onClick={() => {
            setColors(makeColors())
          }}>
            <Typography color="textPrimary">Not Found</Typography>
          </Fab>
        </Grid>
      </Grid>
    </Layout>
  )
}
